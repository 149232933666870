<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width || size"
    :height="height || size"
    :viewBox="viewBoxValues"
    stroke-width="2"
    stroke="inherit"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <g>
      <path :d="mdiIconPath" />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      required: false,
    },
    width: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      mdiIconPath: '',
    }
  },
  computed: {
    viewBoxValues(): string {
      // return '0 0 32 32';
      if (this.size == 24) return '0 0 24 24';
      return `0 0 ${((this.height || this.size) * (100 / 135)).toFixed()} ${((this.width || this.size) * (100 / 135)).toFixed()}`
    }
  },
  watch: {
    name: {
      async handler(newValue: string) {
        try {
          const icons: any = await import('../../plugins/Icons');
          this.mdiIconPath = icons[newValue];
        } catch (error) {
          console.log('Failed to load new icon: ', newValue);
        }
      }
    }
  },
  async created() {
    try {
      const icons: any = await import('../../plugins/Icons');
      this.mdiIconPath = icons[this.name];
    } catch (error) {
      console.log('Failed to load icon: ', this.name);
    }
  }

});
</script>

<style lang="scss" scoped>
</style>