import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eba71a98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view" }
const _hoisted_2 = { class: "profile-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProfileCard = _resolveComponent("ProfileCard")!
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProfileCard)
    ]),
    _createVNode(_component_router_link, {
      title: "Explore",
      class: "button",
      tag: "a",
      to: { name: 'Explore' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_icon, { name: "mdiGlobe" })
      ]),
      _: 1
    })
  ]))
}