import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showBack)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)), ["prevent"])),
          href: "."
        }, [
          _createVNode(_component_icon, { name: "mdiArrowLeft" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.showHome)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: "/"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, { name: "mdiHome" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}