<template>
  <div class="card">
    <section
      title="Akib Khan"
      class="img-wrapper"
      :style="{ 'background-image': `url(${myPhoto})` }"
    ></section>
    <section class="overlay">
      <header>
        <h2 title="Akib Khan">Akib Khan</h2>
      </header>
      <p class="bio">
        Hi there!
        <br />I design and build Softwares
      </p>

      <p class="contact">
        <a title="Gmail" href="mailto:contact@akibrk.com">
          <i class="devicon-google-plain"></i>
        </a>
        <a title="GitHub" href="https://github.com/akibrk" target="_blank">
          <i class="devicon-github-original"></i>
        </a>
        <a
          title="LinkedIn"
          href="https://linkedin.com/in/akibrk"
          target="_blank"
        >
          <i class="devicon-linkedin-plain"></i>
        </a>
      </p>
      <section class="stack">
        <div class="stack-icons">
          <i
            v-for="(item, index) in visibleStack"
            :key="index"
            :title="item"
            @mouseenter="currentFocusItem = item"
            @mouseleave="currentFocusItem = undefined"
            :class="`devicon-${item}-plain`"
          ></i>
        </div>
        <div class="stack-show-more">
          <section class="stack-show-more-button">
            <a
              :style="{
                transform:
                  showTransitionShowMore && !showFullStack
                    ? 'translateY(5px)'
                    : 'translateY(0px)',
              }"
              v-if="!showFullStack"
              @click.prevent="toggleStack"
              class="toggle-button"
              href="."
              @mouseenter="showTransitionShowMore = true"
              @mouseleave="showTransitionShowMore = false"
            >
              <icon name="mdiArrowDown"></icon>
            </a>
            <span
              :style="{
                transform:
                  showTransitionShowMore && !showFullStack
                    ? 'translateY(5px)'
                    : 'translateY(0px)',
                opacity: showTransitionShowMore && !showFullStack ? 1 : 0,
              }"
              >Show more</span
            >
          </section>
          <section class="stack-show-less-button">
            <a
              v-if="showFullStack"
              :style="{
                transform:
                  showTransitionShowLess && showFullStack
                    ? 'translateY(-5px)'
                    : 'translateY(0px)',
              }"
              href="."
              @click.prevent="toggleStack"
              class="toggle-button"
              @mouseover="showTransitionShowLess = true"
              @mouseleave="showTransitionShowLess = false"
            >
              <icon name="mdiArrowUp"></icon>
            </a>
            <span
              :style="{
                transform:
                  showTransitionShowLess && showFullStack
                    ? 'translateY(-5px)'
                    : 'translateY(0px)',
                opacity: showTransitionShowLess && showFullStack ? 1 : 0,
              }"
              >Show less</span
            >
          </section>

          <div v-if="currentFocusItem" class="show-zoomed-in">
            <i
              :title="currentFocusItem"
              :class="`devicon-${currentFocusItem}-plain colored`"
            ></i>
            <span>{{ currentFocusItem }}</span>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProfileCard',
  props: {
    photoName: {
      type: String,
      default: 'akib_22.jpg',
    },
  },
  data() {
    return {
      currentFocusItem: undefined,
      showTransitionShowMore: false,
      showTransitionShowLess: false,
      showFullStack: false,
      stack: [
        'typescript',
        'csharp',
        'cplusplus',
        'c',
        'json',
        'yaml',
        'nuget',
        'npm',
        'mysql',
        'azuresqldatabase',
        'postgresql',
        'mongodb',
        'dynamodb',
        'cosmosdb',
        'redis',
        'vitejs',
        'postman',
        'dbeaver',
        'figma',
        'dotnetcore',
        'express',
        'vuejs',
        'vuetify',
        'tailwindcss',
        'bootstrap',
        'bulma',
        'docker',
        'azure',
        'amazonwebservices',
        'digitalocean',
        'cloudflare',
        'git',
        'gitlab',
        'github',
        'bitbucket',
        'jira',
        'confluence',
      ],
    };
  },
  computed: {
    myPhoto(): string {
      return require(`@/assets/photos/${this.photoName}`);
    },
    visibleStack() {
      return this.showFullStack ? this.stack : this.stack.slice(0, 12);
    },
  },
  methods: {
    toggleStack() {
      this.showFullStack = !this.showFullStack;
    },
  },
});
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
.card {
  max-width: 100%;
  width: 23rem;
  overflow: hidden;
  .img-wrapper {
    background-repeat: no-repeat;
    background-clip: content-box;
    background-position: center;

    background-size: cover;
    @extend .primary-border;
    border-radius: $primary-radius;
    border-bottom: 0;
    height: 22rem;
    position: relative;
    z-index: -2;
  }

  .overlay {
    $border-top-rad: 2.5rem;
    border: none;
    z-index: 20;
    margin-top: -$border-top-rad;
    height: 24rem;
    border-top-left-radius: $border-top-rad;
    border-top-right-radius: $border-top-rad;
    background-color: $background-color-light;
    header {
      display: block;
      padding: 1rem;
      h2 {
        margin: 0;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .bio {
      border: 0.15rem solid $background-color;
      border-left: none;
      border-right: none;
      margin: 0 2rem;
      padding-top: 1rem;
      padding-bottom: 2rem;

      text-align: center;
      font-weight: bold;
    }
    .contact {
      text-align: center;
      margin: 1rem;
      a {
        margin: 0 1rem;
        font-size: 1.8rem;
        transition: font-size 0.5s;
      }
      a:hover {
        font-size: 1.2rem;
      }
    }
    .stack {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-top: 0.15rem solid $background-color;
      margin: 0 1rem;
      padding: 1rem;
      font-size: 1.3rem;

      .stack-icons {
        display: flex;
        flex-wrap: wrap;

        justify-content: center;
        i {
          font-size: 1.2rem;
          display: inline-block;
          margin: 0.1rem;
        }
      }
    }
  }
}
.stack-show-more {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    display: block;
    transition: opacity 0.5s, transform 0.5s;
    color: $accent-color;
    font-size: 0.5rem;
  }
}

.toggle-button {
  transition: transform 0.5s;
  display: block;
  svg {
    transition: fill 1s;
  }
  svg:hover {
    fill: $accent-color;
    stroke: none;
  }
}

.show-zoomed-in {
  font-size: 3rem;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.815);
  height: 7rem;
  width: 7rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: capitalize;

  span {
    font-size: 0.9rem;
    color: $primary-font-color;
    word-break: break-all;
  }
}
</style>
