import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import IconMdi from '@/components/utils/IconMdi.vue';
import Navigation from '@/components/utils/Navigation.vue';

createApp(App)
  .component('Icon', IconMdi)
  .component('Navigation', Navigation)
  .use(createPinia())
  .use(router)
  .mount('#app');
