import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import NotFound from '@/views/error/NotFound.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Intro',
    },
  },
  {
    path: '/explore',
    name: 'Explore',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "explore" */ '../views/Explore.vue'),
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () =>
      import(/* webpackChunkName: "explore" */ '../views/Projects.vue'),
  },
  // {
  //   path: '/resources',
  //   name: 'Resources',
  //   component: () => import(/* webpackChunkName: "explore" */ '../views/Resources.vue'),
  // },
  // {
  //   path: '/guides',
  //   name: 'Guides',
  //   component: () => import(/* webpackChunkName: "guides" */ '../views/Guides.vue'),
  // },
  // {
  //   path: '/guides/:guid',
  //   name: 'GuideViewer',
  //   meta: {
  //     title: 'Guide Viewer',
  //   },
  //   component: () => import(/* webpackChunkName: "guides" */ '../views/NotesViewer.vue'),
  // },
  // {
  //   path: '/notes',
  //   name: 'Notes',
  //   component: () => import(/* webpackChunkName: "guides" */ '../views/Notes.vue'),
  // },
  // {
  //   path: '/gallery',
  //   name: 'Gallery',
  //   component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue'),
  // },
  // {
  //   path: '/gallery/:guid',
  //   name: 'GalleryViewer',
  //   meta: {
  //     title: 'Gallery Viewer',
  //   },
  //   component: () => import(/* webpackChunkName: "gallery" */ '../views/ImageViewer.vue'),
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    meta: {
      title: 'Page not found',
    },
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title || to.name} - Akib Khan`;
  next();
});

export default router;
