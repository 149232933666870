<template>
  <div class="view">
    <div class="profile-wrapper">
      <ProfileCard></ProfileCard>
    </div>
    <router-link
      title="Explore"
      class="button"
      tag="a"
      :to="{ name: 'Explore' }"
    >
      <icon name="mdiGlobe"></icon>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProfileCard from '@/components/ProfileCard.vue';
export default defineComponent({
  name: 'Home',
  components: { ProfileCard },
});
</script>
<style lang="scss" scoped>
@import '@/assets/scss/main.scss';

.profile-wrapper {
  max-width: 90vw;
  margin-top: 2rem;
  z-index: 12;
  transition: height 2s;
}
// .profile-wrapper:hover {
//   transform: scale(1.08);
// }

.button {
  margin: 3rem auto;
  width: 10rem;
  text-align: center;
  transition: transform 0.5s;
}
.button:hover {
  transform: scale(1.1);
}
</style>
