import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "viewBox"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.width || _ctx.size,
    height: _ctx.height || _ctx.size,
    viewBox: _ctx.viewBoxValues,
    "stroke-width": "2",
    stroke: "inherit",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, [
    _createElementVNode("g", null, [
      _createElementVNode("path", { d: _ctx.mdiIconPath }, null, 8, _hoisted_2)
    ])
  ], 8, _hoisted_1))
}